<template>
  <div>
    <errors v-if="result.error" :result="result"></errors>
    <slot v-else></slot>
  </div>
</template>
<script>
import check from "@/plugin/check/index.js";
export default {
  components: {
    errors() {
      return import("./errors.vue");
    }
  },
  props: {},
  data() {
    return {
      id: new Date(),
      result: true
    };
  },
  computed: {},
  mounted() {
    this.setCheck();
  },
  methods: {
    setCheck() {
      const c = new check();
      c.data = this.$store.state.business.unsaved;
      c.store = this.$store;
      c.parent = this;
      c.check();
      this.result = c.result;
    }
  }
};
</script>
<style lang="sass" scoped></style>

export default function() {
  this.data = false;
  this.store = false;
  this.result = { error: false, todo: [] };
  this.stop = false;
  this.services = false;

  this.check = function() {
    this.services = this.store.state.service.services;
    [
      "checkServiceCount",
      "checkWeekdaysCount",
      "checkServiceCount",
      "checkServicePerWeekday"
    ].forEach(
      function(func) {
        if (!this.stop) {
          this[func]();
        }
      }.bind(this)
    );
  };

  this.checkServiceCount = function() {
    if (
      typeof this.services !== "object" ||
      Object.keys(this.services).length === 0
    ) {
      this.result.error = true;
      this.result.todo.push({
        msg: this.parent.$t("services.editor.add", { count: "1." }),
        site: "services"
      });
      this.stop = true;
    }
  };

  this.checkWeekdaysCount = function() {
    if (
      typeof this.data.include.weekdays !== "object" ||
      Object.keys(this.data.include.weekdays).length === 0
    ) {
      this.result.error = true;
      this.result.todo.push({
        msg: this.parent.$t("services.duration.info"),
        site: "time"
      });
      this.stop = true;
    }
  };

  this.checkServicePerWeekday = function() {
    Object.values(this.data.include.weekdays).forEach(
      function(w) {
        if (!isNaN(parseInt(w[2])) && !this.stop) {
          this.result.error = true;
          this.result.todo.push({
            msg: this.parent.$t("services.duration.info"),
            site: "time"
          });
          this.stop = true;
        }
      }.bind(this)
    );
  };
}

import datepicker from "./datepicker.js";
import eventpicker from "./eventpicker.js";
import services from "./services.js";

export default function() {
  this.data = false;
  this.store = false;
  this.parent = false;

  this.check = function() {
    let c = {};

    switch (this.data.type) {
      case "datepicker":
        c = new datepicker();
        break;
      case "eventpicker":
        c = new eventpicker();
        break;
      case "services":
        c = new services();
        break;
    }
    c.data = this.data;
    c.store = this.store;
    c.parent = this.parent;
    c.check();
    this.result = c.result;
  };
}

export default function() {
  this.data = false;
  this.store = false;
  this.result = { error: false, todo: [] };
  this.stop = false;

  this.check = function() {
    [
      "checkEventsCount",
      "checkSameDate",
      "checkTimeRange",
      "changeEventsOrder"
    ].forEach(
      function(func) {
        if (!this.stop) {
          this[func]();
        }
      }.bind(this)
    );
  };

  this.checkEventsCount = function() {
    if (Object.keys(this.data.events).length === 0) {
      this.result.error = true;
      this.result.todo.push({
        msg: this.parent.$t("settings.event.add", { count: "1" }),
        site: "time"
      });
      this.stop = true;
    }
  };

  this.checkSameDate = function() {
    const checker = [];
    Object.keys(this.data.events).forEach(
      function(k) {
        if (this.stop) {
          return true;
        }
        let search = this.data.events[k].begin + "" + this.data.events[k].end;
        if (checker.includes(search)) {
          this.result.error = true;
          this.result.todo.push({
            msg: this.parent.$t("settings.event.same"),
            site: "time"
          });
          this.stop = true;
        }
        checker.push(search);
      }.bind(this)
    );
  };

  this.checkTimeRange = function() {
    if (Object.keys(this.data.events).length === 1) {
      return false;
    }
    const dateObject = [];
    Object.keys(this.data.events).forEach(
      function(k) {
        dateObject.push([
          this.createDate(this.data.events[k].begin),
          this.createDate(this.data.events[k].end)
        ]);
      }.bind(this)
    );

    dateObject.forEach(
      function(v1, k1) {
        if (this.stop) {
          return true;
        }
        dateObject.forEach(
          function(v2, k2) {
            if (this.stop) {
              return true;
            }
            if (k2 == k1) {
              return true;
            }
            if (
              v1[0].getTime() <= v2[1].getTime() &&
              v1[1].getTime() >= v2[0].getTime()
            ) {
              this.result.error = true;
              this.result.todo.push({
                msg: this.parent.$t("settings.event.sameTimerange", {
                  event1: k1 + 1,
                  event2: k2 + 1
                }),
                site: "time"
              });
              this.stop = true;
            }
          }.bind(this)
        );
      }.bind(this)
    );
  };

  this.createDate = function(d) {
    const eventDate = d.split(" ");
    eventDate[0] = eventDate[0].split("-");
    eventDate[1] = eventDate[1].split(":");
    const tempDate = new Date();
    tempDate.setYear(eventDate[0][0] * 1);
    tempDate.setMonth(eventDate[0][1] * 1 - 1);
    tempDate.setDate(eventDate[0][2] * 1);
    tempDate.setHours(eventDate[1][0] * 1);
    tempDate.setMinutes(eventDate[1][1] * 1);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);
    return tempDate;
  };

  this.calculateEventsOrder = function() {
    const reorder = {};
    const controle = [];
    this.data.events.forEach(
      function(d, a) {
        const tempDate = this.createDate(d.begin);
        reorder[tempDate.getTime() + "" + a] = a;
        controle.push((tempDate.getTime() + "" + a) * 1);
      }.bind(this)
    );

    const newData = [];
    const maxApplicants = [];
    controle.sort().forEach(
      function(c) {
        newData.push(this.data.events[reorder[c]]);
        maxApplicants.push(this.data.max_applicants[reorder[c]]);
      }.bind(this)
    );

    this.data.events = newData;
    this.data.max_applicants = maxApplicants;
  };

  this.changeEventsOrder = function() {
    if (Object.keys(this.data.events).length === 1) {
      return false;
    }

    this.calculateEventsOrder();
    this.store.commit("business/prefetch", this.data);
    this.store.commit("business/update", this.data);
  };
}

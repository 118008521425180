export default function() {
  this.data = false;
  this.result = { error: false, todo: [] };
  this.stop = false;
  this.parent = false;

  this.check = function() {
    ["checkWeekdaysCount", "checkPerWeekday"].forEach(
      function(func) {
        if (!this.stop) {
          this[func]();
        }
      }.bind(this)
    );
  };

  this.checkWeekdaysCount = function() {
    if (
      typeof this.data.include.weekdays !== "object" ||
      Object.keys(this.data.include.weekdays).length === 0
    ) {
      this.result.error = true;
      this.result.todo.push({
        msg: this.parent.$t("time.weekdays.info"),
        site: "time"
      });
      this.stop = true;
    }
  };

  this.checkPerWeekday = function() {
    Object.keys(this.data.include.weekdays).forEach(
      function(k) {
        if (isNaN(parseInt(this.data.include.weekdays[k][2]))) {
          this.data.include.weekdays[k][2] = 60;
          this.data.next_appointments[k] = 60;
          this.data.max_applicants[k] = 1;
          this.data.exclude.weekdays[k] = [];
          this.stop = true;
        }
      }.bind(this)
    );

    if (this.stop) {
      this.store.commit("business/prefetch", this.data);
      this.store.commit("business/update", this.data);
      this.result.error = true;
      this.result.todo.push({
        msg: this.parent.$t("time.weekdays.info"),
        site: "time"
      });
    }
  };
}
